/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@tailwind utilities;

/* For Circular SVG Loading Component */
.circular-chart {
  display: block;
  max-height: 250px;
  max-width: 80%;
  transform: scaleX(-1);
}

.circle {
  animation: progress 1s ease-out forwards;
  fill: none;
  stroke-linecap: round;
  stroke-width: 4;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.ais-SearchBox, .ais-SearchBox-form, .ais-SearchBox-input {
  border: none;
  border-radius: 20px;
  width: 100%;
}
.ais-SearchBox-submit, .ais-SearchBox-reset {
  display: none;
}

#search-autocomplete {
  width: 100%;
}
